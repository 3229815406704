const localeValues = {
    pageHeader: {
        title: 'PIKA'
    },
    login: {
        title: '监考系统',
        username: '账号',
        password: '密码',
        login: '登录'
    },
    menu:{
        home:'首页',
        manageExam:'试卷管理',
        createExam:'创建试卷'
    },
    footer:{
        privacy:'京ICP备2022015257号-1'
    },
    home:{
        title:'近期的考试',
        sumBefore:'共',
        sumAfter:'场',
        all:'全部考试',
        start:'进行中',
        before:'未开始',
        end:'已结束',
        startQuiz:{
            time:'考试时间',
            total:'考生总数',
            status1:'未登录',
            status2:'登记中',
            status3:'答题中',
            status4:'已交卷',
            people:'监考人员',
            button1:'查看详情',
            button2:'编辑',
            button3:'监考配置'
        },
        beforeQuiz:{
            time:'考试时间',
            name:'试卷名称',
            total:'考生总数',
            type:'监考方式',
            button1:'查看详情',
            button2:'编辑',
            button3:'监考配置'
        },
        finishQuiz:{
            total:'考生总数',
            status1:'未登录考生',
            status2:'到考考生',
            button1:'查看详情',
            button2:'考生情况 ',
        },
    }
}

export default localeValues;