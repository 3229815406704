/**
 * @file: routes/dashboard
 * @author jianghaoran
 */

import loadable from 'react-loadable';
import {Spin} from 'skd';
import {Redirect} from 'react-router-dom';

const Loading = Spin;

const routers = [
    {
        path: '/',
        exact: true,
        // 暂无dashboard 直接跳入监考图片页面
        component: loadable({
            loader: () =>
                import(
                    /* webpackChunkName: 'dashboard' */ /* webpackMode: "lazy" */
                    '@views/dashboard'
                ),
            loading: Loading
        }),
    }
];

export default routers;
