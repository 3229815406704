/**
 * @file 页面标题
 * @author jianghaoran
 */
import React from 'react';
import {Menu} from 'skd';
import { HomeOutlined, CopyOutlined } from '@ant-design/icons';
import './index.less';
import {useLocaleReceiver} from '@local/locale-receiver';
import {useState,useEffect} from 'react'
import { useHistory , Link} from 'react-router-dom';

const PREFIX = "page-menu";

export default props => {
    const _history = useHistory();
    const [menuLocale] = useLocaleReceiver('menu');
    const [selectedKey ,setSelectedKey]= useState('');
    const items = [
        { label: <Link to={'/'}>{menuLocale['home']}</Link>, key: '/', icon:<HomeOutlined style={{color:'#1573FF'}} /> }, // 菜单项务必填写 key
        {
            label: menuLocale['manageExam'],
            key: '/manageExam',
            icon:<CopyOutlined style={{color:'#1573FF'}}/>,
            children: [{ label:  <Link to={'/createExam'}>{menuLocale['createExam']}</Link>, key: '/createExam', icon:<CopyOutlined style={{color:'#1573FF'}}/> }],
        },
    ];

    useEffect(()=>{
        setSelectedKey(_history.location.pathname);
    },[_history.location.pathname] )

    return (
        <Menu className={PREFIX}  mode="inline" items={items} selectedKeys={[selectedKey]} inlineIndent={24}/>
    // <Menu className={PREFIX} mode={'inline'}>
    //     <Menu.Item key={1}> <BankTwoTone /> <span>菜单项一</span></Menu.Item>
    //     <Menu.Item key={2}><BankTwoTone /> <span>菜单项二</span></Menu.Item>
    //     <Menu.SubMenu key={3} title={
    //         <span>
    //             <BankTwoTone />
    //             <span>Navigation One</span>
    //           </span>
    //     }>
    //         <Menu.Item key={4}> <BankTwoTone /> <span>子菜单项</span></Menu.Item>
    //     </Menu.SubMenu>
    // </Menu>
    )
}